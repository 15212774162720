@import 'variables';

.Ylapalkki {
  height: 48px;
  margin: 0;
  padding: 8px 32px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

  h1 {
    color: #000;
    font-size: 31px;
    line-height: 31px;
    font-weight: 400;
    margin: -3px 0 0 0;
  }

  span.pystyviiva {
    color: #000;
    font-size: 29px;
    line-height: 29px;
    font-weight: 400;
    margin: -4px 0 0 0;
    padding: 0 9px 0 12px;
  }

  button {
    margin: 0;
    text-decoration: none;
  }

  .Top1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    img {
      // margin-top: 3px;
      height: 32px;
    }
  }

  .Top2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    a.topLink {
      display: inline-flex;
      color: $purple;
      margin-top: 0px;
      height: 24px;
      text-decoration: none;

      svg {
        margin-top: 4px;
        margin-right: 5px;
      }

      &:first-of-type {
        margin-right: 32px;
      }
    }

    a.topLink:focus,
    a.topLink:active,
    a.topLink:hover {
      cursor: pointer;
      color: $purple;
      text-decoration: underline;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: $bp_max_width_tablet) {
  .Ylapalkki {
    width: 100%;
    padding: 8px 15px 10px 15px;
    height: 42px;

    a span {
      display: none;
    }

    .Top1 {
      h1 {
        font-size: 24px;
        line-height: 24px;
        margin: -1px 0 0 0;
      }
    
      span.pystyviiva {
        font-size: 22px;
        line-height: 22px;
        margin: -1px 0 0 0;
        padding: 0 8px 0 11px;
      }

      img {
        height: 24px;
      }
    }

    .Top2 a.topLink svg {
      width: 24px;
      height: 24px;
      margin-top: 1px;
      margin-right: 0;
    }

    .Top2 a.topLink:first-of-type {
        display: none;
    }

  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {
  .Ylapalkki {
    padding: 9px 10px 11px 10px;

    .Top1 {
      h1 {
        font-size: 22px;
        line-height: 22px;
        margin: 0;
        white-space: nowrap;
      }
    
      span.pystyviiva {
        font-size: 20px;
        line-height: 20px;
        margin: -1px 0 0 0;
        padding: 0 7px 0 9px;
      }

      img {
        height: 22px;
      }
    }

    .Top2 a.topLink svg {
      width: 22px;
      height: 22px;
      margin-top: 1px;
    }
  }
}

@import 'variables';

.Kuvatyypit,
.KuvatyypitMobiili {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  background-color: #fff;
  padding: 20px;

  h2 {
    display: flex;
    flex-direction: column;
    margin: 5px auto 15px;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }

  button.typeToggler {
    color: $purple;
    text-decoration: none;
    width: fit-content;
    margin: 0 auto;

    &:hover,
    &:active {
      text-decoration: underline;
    }

    &:focus {
      background-color: $light_gray;
      border: 1px solid #000;
      box-shadow: $focus_shadow !important;
      outline: none;
    }
  }

  .KuvatyypitButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button:not(.typeToggler) {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 350px;
      min-width: 125px;
      margin: 5px auto;
      box-shadow: unset !important;
      justify-content: space-between;
      padding: 5px 5px 5px 18px;
      border: 1px solid #000;
      border-radius: 8px;
      font-weight: 450;
      background-color: #fff;
      color: #000;

      span {
        margin: auto 0;
      }

      .checkBox {
        color: #000;
        width: 22px;
        height: 22px;
        margin: auto 0;
      }

      img {
        height: 50px;
        width: auto;
      }

      &:hover,
      &:active {
        cursor: pointer;
        background-color: $light_gray;
        color: #000;
        border: 1px solid #000;
        outline: none;
        box-shadow: none;
      }

      &:focus {
        background-color: $light_gray;
        color: #000;
        border: 1px solid #000;
        outline: none;
        box-shadow: $focus_shadow !important;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .toggleButton {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #000;
    margin: 30px auto 5px;
    padding: 5px 20px;
    width: 300px;
    height: 40px;

    svg {
      font-size: 1.25rem;
      margin: auto 0;
      color: #000;
    }

    span {
      margin-top: auto;
      margin-bottom: auto;
      color: #000;
    }
  }

  .toggleButton:hover,
  .toggleButton:focus,
  .toggleButton:active {
    background-color: $light_gray;
    border-color: #000;
  }
}

.Kuvatyypit:first-of-type {
  display: none;
}

.Kuvatyypit:last-of-type {
  display: flex;
}

.KuvatyypitMobiili {
  display: none;
}

@media only screen and (max-width: $bp_max_width_tablet) {
  .Kuvatyypit:first-of-type {
    display: flex;
  }

  .Kuvatyypit:last-of-type {
    display: none;
  }

  .Kuvatyypit,
  .KuvatyypitMobiili {
    .KuvatyypitButtons {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 640px;
      margin: auto;

      button:not(.typeToggler) {
        max-width: 300px;
        margin: 5px;
        height: 62px;

        .checkBox {
          margin-top: 14px;
        }

        p {
          font-size: 1rem;
          margin-top: 12px;
        }

        img {
          height: 50px;
          width: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {
  .Kuvatyypit:first-of-type {
    display: none;
  }

  .KuvatyypitMobiili {
    display: flex;
    flex-direction: column;
    padding: 0;

    button.typeToggler {
      width: auto;
      min-width: 300px;
      max-width: 350px;
    }
  }
}

@import 'variables';

.tabLine {
  display: flex;
  margin: 0;
  padding: 0;
  height: 45px;
  text-align: center;

  a {
    text-decoration: none;

    .activeTab {
      background-color: #fff;
      color: #000;
      height: 45px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding-top: 10px;
      width: 300px;
      font-weight: 700;
    }

    .notActiveTab {
      background-color: $gray;
      color: #000;
      height: 45px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding-top: 10px;
      width: 300px;
      font-weight: 400;
    }

    .activeTab:hover,
    .activeTab:focus,
    .activeTab:active,
    .notActiveTab:hover,
    .notActiveTab:focus,
    .notActiveTab:active {
      text-decoration: underline;
    }
  }

  a:hover {
    cursor: pointer;
  }

  a:focus,
  a:active {
    text-decoration: underline;
    color: #000;
  }
}

@media only screen and (max-width: $bp_max_width_tablet) {
  .tabLine {
    max-width: unset;
    width: 100%;
    margin: 0;
    a {
      width: 50%;

      .notActiveTab,
      .activeTab {
        width: 100%;

        p {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {
  .activeTab p,
  .notActiveTab p {
    font-size: 1rem;
  }
}

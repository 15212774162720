@import 'variables';

.Hae {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  padding: 30px;
}

#hakukentta {
  display: flex;
  flex-wrap: wrap;

  .form-control {
    height: 40px;
    margin: 0px;
    width: 300px;
    max-width: 400px;
    margin-right: 10px;
    background-color: #ffffff;
    border-color: #444444;

    &:focus {
      box-shadow: $focus_shadow;
    }
  }

  #searchButton {
    background-color: $gray;
    color: #000;
    border-color: #000;
    min-height: 40px;
  }

  #searchButton:hover {
    background-color: $light_gray;
  }

  #searchButton:focus {
    background-color: $light_gray;
    box-shadow: $focus_shadow;
  }
}

@media only screen and (max-width: $bp_max_width_tablet) {
  .Hae {
    justify-content: center;
    padding: 30px 0px;
  }

  #hakukentta {
    width: 100%;
    max-width: 400px;
    justify-content: center;

    .form-control {
      max-width: 100%;
      height: 50px;
      margin-left: unset;
      margin-right: 5px;
    }

    #searchButton {
      width: 80px;
      height: 50px;
    }

    .invalid-feedback {
      width: max-content;
    }
  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {
  .Hae {
    padding-left: 0;
    padding-right: 0;
  }

  #hakukentta {
    width: 300px;

    .form-control {
      width: 235px;
      height: 50px;
      margin-right: 5px;
      margin-left: 0;
    }

    #searchButton {
      width: 60px;
      height: 50px;
    }
  }
}

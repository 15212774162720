@import 'variables';

.KuvatContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

.KuvatControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  max-height: 60px;
  width: 100%;
  margin: 0;
  padding: 10px 27px 10px 25px;

  .KuvatControlsCol {
    display: flex;
  }

  .KuvatControlsCol > p {
    margin: 8px auto;
  }

  .KuvatControlsCol:first-of-type {
    .EdellinenButton {
      display: flex;
      margin-left: 2px;
      padding-top: 0.5rem;
      height: 40px;
      width: 224px;
      min-width: 224px;
      justify-content: center;
      color: #000;
      border: 1px solid #000;
      border-radius: 6px;
      text-decoration: none;

      svg {
        margin-top: 4px;
        margin-right: 6px;
      }
    }

    .EdellinenButton:focus,
    .EdellinenButton:hover,
    .EdellinenButton:active {
      background-color: $light_gray;
    }
  }

  .KuvatControlsCol:first-of-type {
    justify-content: flex-start;
  }

  .KuvatControlsCol:last-of-type {
    justify-content: flex-end;

    ul.pagination {
      li.page-item {
        .page-link {
          color: #000;
          border: 1px solid #000;
        }

        .page-link:hover,
        .page-link:focus,
        .page-link:active {
          color: #000;
          background-color: $light_gray;
          border-color: #000;
        }
      }

      li.page-item.active {
        .page-link {
          color: #fff;
          background-color: #000;
          border-color: #000;
        }
      }

      li.page-item.disabled {
        .page-link {
          color: #000;
          border-color: #000;
          opacity: 0.35;
        }
      }
    }
  }
}

.Kuvat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;

  a {
    color: #000;
    width: auto;
    padding: 0;
    margin: 10px;
    border-radius: .25rem;
    text-decoration: none;
    transition: all 0.1s ease-in-out;

    .card {
      border: 1px solid #000;
      transition: all 0.1s ease-in-out;
      width: 14rem;
      overflow: hidden;

      .card-body:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        min-height: 11rem;
        max-height: 11rem;

        .card-img {
          margin: auto;
          width: auto;
          height: auto;
          max-width: 192px;
          max-height: 146px;
        }

        .playIcon {
          position: absolute;
          left: calc((222px - 36px) / 2);
          color: $play_icon_color;
          width: 36px;
          height: 36px;
        }
      }

      .card-body:last-of-type {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0 15px 15px;
        min-height: 63px;

        .card-title {
          display: flex;
          flex-direction: column;
          margin: auto 0;
          text-align: center;
          font-size: 1.25rem;
        }
      }
    }
  }

  a:hover,
  a:active,
  a:hover > .card,
  a:active > .card {
    box-shadow: none;
    outline: none;
    background-color: $light_gray;
  }

  a:focus,
  a:focus > .card {
    box-shadow: $focus_shadow;
    outline: none;
    background-color: $light_gray;
  }
}

form.sortMethodForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  label {
    margin-right: 6px;
  }

  select {
    font-size: 15px;
    color: black;
    border-color: black;
    border-radius: 6px;
    padding: 7px 34px 7px 10px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: $light_gray;
    }

    &:focus {
      border-color: black;
      box-shadow: $focus_shadow;
    }
  }
}

.SearchIcon {
  color: rgba(139, 139, 139, 0.33);
  font-size: 10em;
}

.noResults {
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;

  svg {
    margin: 20px auto;
  }

  p {
    font-size: 1.5rem;
  }
}

.EdellinenButtonMobiili {
  display: none;
}

.page-link:focus {
  box-shadow: $focus_shadow;
}

@media only screen and (max-width: $bp_max_width_tablet) {
  .noResults p {
    font-size: 1.3rem;
  }

  .KuvatControls {
    flex-direction: column-reverse;
    max-height: none;
    padding-top: 0;

    .KuvatControlsCol {
      flex-direction: column;
      justify-content: center;
      margin: 8px auto;
      max-height: 40px;
    }

    .KuvatControlsCol:first-of-type .EdellinenButton {
      margin: auto;
    }

    .KuvatControlsCol:last-of-type {
        margin-top: 20px;

      .pagination {
        justify-content: center;
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {

  .KuvatContainer .Kuvat a .card {
    width: 10rem;

    .h5 {
      font-size: 16px;
    }

    .card-body:first-of-type .card-img {
      max-width: 100%;
      max-height: 100%;
    }

    .card-body:first-of-type .playIcon {
      left: calc((158px - 36px) / 2);
    }
  }
}

@media only screen and (max-width: $bp_max_width_mobile_sm) {
  .KuvatContainer .Kuvat a .card {
    width: 15rem;

    .card-body:first-of-type {
      max-height: none;
    }
  }
}

@import 'variables';

.modal-dialog {
  height: calc(100vh - 56px);
  margin-left: calc((100vw - 75vw) / 2) !important;

  .modal-content {
  width: 75vw;
  height: 100%;

    .modal-header {
      .modal-title {
        justify-content: center;
        text-align: center;
        margin-left: auto;
      }

      h3 {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 24px;
      }

      .close {
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 0;
        padding-bottom: 0;
      }

      button:focus {
        box-shadow: $focus_shadow;
        outline: none;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      padding: 20px;
      max-height: calc(100vh - 190px);

      .modalImage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 80px);

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
  
        iframe {
          margin: auto;
          max-width: 100%;
        }
      }

      .modalNavigation {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 25%;
        max-height: 60px;
        margin-top: 20px;
  
        #nextImage {
          color: #212529;
          font-size: 1.4em;
        }
  
        .previousButton, .nextButton {
          display: block;
          width: 150px;
          height: 100%;
          margin: auto;
          background-color: #fff;
          border: 1px solid #212529;
        }
  
        .previousButton:hover,
        .nextButton:hover {
          background-color: $light_gray !important;
        }

        .previousButton:focus,
        .nextButton:focus {
          box-shadow: $focus_shadow;
          outline: none;
        }
      }


    }

    #body2 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      background-color: #fff;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      max-height: 70px;
      padding: 0 20px;
      width: 100%;

      p {
        white-space: nowrap;
        margin-top: auto;
        margin-bottom: auto;
      }

      a {
        display: inline-flex;
        height: 31px;
        margin-left: 10px;

        #cc {
          height: 31px;
          width: auto;
        }
      }

      a:focus {
        box-shadow: none;
        outline: none;
      }

      a:focus > img {
        box-shadow: $focus_shadow;
        outline: none;
      }
    }
  }
}

.modal-open .modal {
  overflow-y: hidden;
}

button.LataaKuva {
  margin: auto 0 auto auto !important;
  background-color: $purple;
  color: white;
  border-color: $dark_purple;
  border-radius: 6px;
  height: 38px;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background-color: $purple !important;
    border-color: black !important;
    box-shadow: 0 0 0 0.2rem rgba(89, 56, 124, 0.5);
    outline: none;
  }
}

@media only screen and (max-width: $bp_max_width_mobile_lg) {
  .modal {
    padding-left: 0 !important;
  }

  .modal-dialog {
    margin-top: 0 !important;
    margin-left: 0 !important;
    height: 100dvh;
  
    .modal-content {
    width: 100vw;
    height: 100%;
  
      .modal-body {
        max-height: calc(100vh - 130px);
      }
  
      #body2 {
  
        p {
          white-space: normal;
        }
  
        a {
          height: 22px;
  
          #cc {
            height: 22px;
            width: auto;
          }
        }
  
      }
    }
  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {
  .modal-dialog {
    .modal-content {
      .modal-body {
        button.LataaKuva {
          display: none;
        }
      }
    }
  }
}

@import 'variables';

.Kategoriat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  min-height: 940px;
  width: 100%;

  .Linkit,
  .AlakategoriaLinkit {
    a {
      display: flex;
      color: #000;
      padding: 0.4rem 0.5rem;
      border: 1px solid #000;
      max-width: 330px;
      height: 60px;
      border-radius: 6px;
      transition: all 0.1s ease-in-out;
      text-align: left;
      align-items: center;
      text-decoration: none;

      p {
        margin: auto 0;
      }
    }

    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      color: #000;
      background-color: $light_gray;
      transition: all 0.1s ease-in-out;
      border: 1px solid #000;
    }
  }

  .Linkit {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 20px;
    max-height: 860px;

    a {
      margin: 5px 20px;
      width: calc((100% - (20px * 6)) / 3);
    }

    svg {
      margin: 0 0 0 auto;
      width: 14px;
      height: 14px;
      opacity: 0.8;
    }
  }

  .AlakategoriaLinkit {
    a {
      margin: 10px auto;
      width: calc(100% - 30px);
    }
  }
}

@media only screen and (max-width: $bp_max_width_tablet) {
  .Kategoriat {
    flex-direction: column;
    min-height: unset;
    padding-bottom: 30px;

    .Linkit {
      flex-wrap: nowrap;
      max-height: none;

      a {
        display: flex;
        color: #000;
        padding: 0.4rem 0.5rem;
        border: 1px solid #000;
        width: 100%;
        max-width: 610px;
        border-radius: 6px;
        transition: all 0.1s ease-in-out;
        margin: 5px auto;
        height: 60px;
      }
    }
  }

  .Kategoriat > .col:first-of-type {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: $bp_max_width_mobile_md) {
  .Kategoriat a p {
    font-size: 1rem;
  }
}

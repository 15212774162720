// Responsiivisuus
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1024px, // Tätä arvoa on tuotu vähän alemmas, BS:n oletus 1200px
  xxl: 1200px
);

// Desktop tila aktivoituu 1024px ja suuremmille näytöille
$bp_max_width_tablet: 1023px; // 768-1023
$bp_max_width_mobile_lg: 767px; // 576-767
$bp_max_width_mobile_md: 575px; // 400-575
$bp_max_width_mobile_sm: 399px; // Pienempi kuin 400

$play_icon_color: #ffffff;

$blue: #003fb4;
$dark_blue: #002872;

$gray: #d3d3d3;
$dark_gray: #8b8b8b;
$light_gray: #e6e6e6;

$purple: #59387c;
$dark_purple: #412c58;

$focus_shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);